import React from "react";
import "./style.scss";
import { text } from "@fortawesome/fontawesome-svg-core";

// Sample data array for teams
const teams = [
  {
    id: 1,
    name: "Gurvir",
    type :"Gurvir",
    image: require("../../images/expert/1.jpg"),
    
    info: [
      { level: "", text: "Gurvir is a dedicated and driven professional with a strong academic background, holding a Bachelor of Commerce degree followed by his law degree from the University of Leicester. As a lawyer, Gurvir combines a deep understanding of business principles with legal expertise, offering a unique approach to problem-solving and strategic thinking. Outside of work, Gurvir is passionate about motorcycles, cycling, and hiking—activities that reflect a love for adventure and personal challenge.  With a commitment to continuous learning and improvement, Gurvir brings energy, enthusiasm, and determination to every every aspect of life, whether in the courtroom, on the trail, or on the road" },
      // { level: "Experience:", text: "10+ Years" },
      // { level: "Location:", text: "Toronto, Canada" },
    ],
    contents: [
      "Bachelor of Law, University of Toronto",
      "Master of Business Administration, Harvard University",
      "Certified in Corporate Law",
    ],
    socialLinks: [
      { platform: "facebook", url: "#" },
      { platform: "twitter", url: "#" },
      { platform: "linkedin", url: "#" },
      { platform: "google-plus", url: "#" },
    ],
    languages: "French (fluent), English (fluent), Greek, Chinese",
    experience: `There are many variations of passages of Lorem Ipsum available,
    but the majority have suffered alteration in some form, by injected humour,
    or randomised words which don't look even slightly believable.`,
  },
  {
    id: 2,
    name: "Afreen",
    type :"Immigration Lawyer",
    image: require("../../images/expert/1.jpg"),
    info: [
      { level: "", text: "Afreen is a dedicated immigration lawyer, licensed in both India and Canada, with extensive experience in immigration law. She has been working in the field since 2017, initially gaining valuable experience as an immigration case manager before becoming a licensed lawyer in 2022. [Lawyer’s Name] completed the Law Practice Program at Ryerson University, which provided her with the practical skills and knowledge needed to succeed in her legal career. Along with her focus on immigration law, she has developed an interest in business law, aiming to broaden her expertise and better serve clients with diverse legal needs. Outside of her legal practice, Afreen is an animal lover, enjoys reading, and has a passion for dancing. She also loves traveling, which allows her to explore new cultures and gain fresh perspectives. Her commitment to both personal and professional growth is evident in every aspect of her life." },
      // { level: "Experience:", text: "10+ Years" },
      // { level: "Location:", text: "Toronto, Canada" },
    ],
    contents: [
      "Bachelor of Law, University of Toronto",
      "Master of Business Administration, Harvard University",
      "Certified in Corporate Law",
    ],
    socialLinks: [
      { platform: "facebook", url: "#" },
      { platform: "twitter", url: "#" },
      { platform: "linkedin", url: "#" },
      { platform: "google-plus", url: "#" },
    ],
    languages: "French (fluent), English (fluent), Greek, Chinese",
    experience: `There are many variations of passages of Lorem Ipsum available,
    but the majority have suffered alteration in some form, by injected humour,
    or randomised words which don't look even slightly believable.`,
  },
];

const TeamMember = () => {
  return (
    <div className="singleTeamArea">
      <div className="container">
        {teams.map((team) => (
          <div key={team.id} className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="singleTeamImgWrap">
                    <div className="singleTeamImg">
                      <img src={team.image} alt={team.name} />
                    </div>
                    <h4>{team.name}</h4>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="singleTeamContent">
                    <h4>{team.type}</h4>
                    <ul className="teamMembarInfo">
                      {team.info.map((info, i) => (
                        <li key={i}>
                          <span>{info.level}</span> {info.text}
                        </li>
                      ))}
                    </ul>
                    <ul className="socialShare">
                      {team.socialLinks.map((link, i) => (
                        <li key={i}>
                          <a href={link.url}>
                            <i className={`fa fa-${link.platform}`}></i>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="singleTeamInfo">
                    <h3>Personal Experience</h3>
                    <p>{team.experience}</p>
                    <h5>Education</h5>
                    <ul>
                      {team.contents.map((content, index) => (
                        <li key={index}>{content}</li>
                      ))}
                    </ul>
                    <h5>Language</h5>
                    <span>{team.languages}</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMember;
