import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/AboutWithoutImage'

// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import familyLaw from '../../../images/common/FamilyLaw.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'
 
import './style.scss'
import config from '../../../config'
const policyText = `
1. Introduction
Ardent Law Firm is committed to protecting your privacy and maintaining the confidentiality of your personal information. This policy explains how we collect, use, disclose, and safeguard information when you visit our website or communicate with us.

2. Information We Collect
• Personal Information: Name, contact details, and any details provided through inquiries or consultations.
• Technical Information: IP address, browser type, and website usage data collected through cookies and analytics tools.

3. How We Use Your Information
Your information is used to:
• Respond to inquiries and provide legal services.
• Communicate with you about your matters or updates from our firm.
• Improve website functionality and user experience.

We do not sell or share personal information for marketing purposes.

4. Confidentiality & Security
We take appropriate measures to protect personal and confidential information. However, contacting us through this website does not establish a lawyer-client relationship. Please avoid sending sensitive information until a formal engagement is established.

5. Your Choices
You may:
• Request access to or correction of your personal information.
• Opt out of marketing communications at any time.
• Disable cookies in your browser settings.

6. Third-Party Links
Our website may contain links to external sites. We are not responsible for their privacy practices and encourage you to review their policies.

7. Disclaimer
The content on this website, including this Privacy Policy, is for informational purposes only and does not constitute legal advice. Accessing this website or submitting information does not establish a lawyer-client relationship. If you require legal assistance, please contact us directly.

8. Updates to This Policy
This policy may be updated periodically. The latest version will always be available on our website.
`;
const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Privacy Policy'}
]
const PrivacyPolicy = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Privacy Policy"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <div className="container">
        <div className="row">
          
          <div className="col-lg-12">
            <div className="policyContent" style={{ padding: '20px', whiteSpace: 'pre-wrap', lineHeight: '1.6' }}>
                {policyText}
            </div>
      </div>
      </div>
      </div>
            <FooterArea/>
        </Fragment>
    );
};

export default PrivacyPolicy;
