import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import Form from "../../../components/Form";
// images
import breadcumb from "../../../images/breadcumb/1.jpg";
import config from "../../../config";
import Map from "../../../components/Map"
import "./style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Contact" }];

const ContactPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Contact Us"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />

      <div className="contactusPageArea">
        <div className="container">
 
          <div className="row">
            <div className="col-lg-6">
              <div className="contactUsInfo">
                <h3>Our Contacts </h3>
                <h3>Mississauga</h3>
                <h4>Address</h4>
                <span>{config.Address.Mississauga.street + "," + config.Address.Mississauga.city}</span>
                <h4>Phone</h4>
                <span>{config.Address.Mississauga.phoneInternational}</span>
                {/* <span>+1 (905) 795-5500</span> */}
                <h4>Email</h4>
                <span>{config.Address.Mississauga.emailId}</span>
                <Map Mississauga={true}/>
              </div>
            
            </div>
            <div className="col-lg-6">
              <div className="contactUsInfo">
                <h3>&nbsp;</h3>
                <h3>Cambridge</h3>

                <h4>Address</h4>
                <span>{config.Address.Cambridge.street + "," + config.Address.Cambridge.city}</span>
                <h4>Phone</h4>
                <span>{config.Address.Cambridge.phoneInternational}</span>
                {/* <span>+1 (905) 795-5500</span> */}
                <h4>Email</h4>
                <span>{config.Address.Cambridge.emailId}</span>
                <Map Mississauga={false}/>
              </div>
            
            </div>
            </div>
            <div className="row">
            <div className="col-lg-10">
              <div className="contactUSForm">
                <h3>Quick Contact Form</h3>
                <Form addressInfo={true}  black={true} />
              </div>
            </div>
          </div>
        </div>
      </div>

    
      <FooterArea />
    </Fragment>
  );
};
export default ContactPage;
