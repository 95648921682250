import React, { Fragment, Component } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "../Pages/Home";
import FamilyLaw from "../Pages/FamilyLaw";
import CivilLitigation from "../Pages/CivilLitigation";
import AboutUs from "../Pages/AboutUs";
import TeamPage from "../Pages/TeamPage";
import ImmigrationLaw from "../Pages/ImmigrationLaw";
import BusinessLaw from "../Pages/BusinessLaw";
import CriminalLaw from "../Pages/CriminalLaw";
import RealEstateLaw from "../Pages/RealEstateLaw";
import WillsEstate from "../Pages/WillsEstate";
//import PracticePage from "../Pages/PracticePage";
// import PracticeSinglePage from "../Pages/PracticeSinglePage";
// import PortfolioPage from "../Pages/PortfolioPage";
// import SinglePortfolioPage from "../Pages/SinglePortfolioPage";
 
import SingleTeamPage from "../Pages/SingleTeamPage";
import ContactPage from "../Pages/ContactPage";
import BlogLeftPage from "../Pages/BlogLeftPage";
import BlogRightPage from "../Pages/BlogRightPage";
import BlogFullWidth from "../Pages/BlogFullWidth";
import "./App.css";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
 
class App extends Component {
  render() {
    return (
      <Fragment>
 
        <Router>
          <ToastContainer autoClose={2500} position="top-center" />
          <Routes>
            <Route  path="/"   element={<Home/>} />
            <Route  path="/AboutUs"   element={<AboutUs/>} />
            <Route  path="/OurTeam"   element={<TeamPage/>} />
            <Route  path="/PrivacyPolicy"   element={<PrivacyPolicy/>} />
            <Route  path="/FamilyLaw"   element={<FamilyLaw/>} />
            <Route  path="/CivilLitigation"   element={<CivilLitigation/>} />
            <Route  path="/BusinessLaw"   element={<BusinessLaw/>} />
            <Route  path="/CriminalLaw"   element={<CriminalLaw/>} />
            <Route  path="/ImmigrationLaw"   element={<ImmigrationLaw/>} />
            <Route  path="/RealEstateLaw"   element={<RealEstateLaw/>} />
            <Route  path="/WillsEstate"   element={<WillsEstate/>} />
  
            {/* <Route
               
              path="/practice-details"
              component={PracticeSinglePage}
            />
            <Route   path="/case-stadies" component={PortfolioPage} />
            <Route
               
              path="/case-stadies-details"
              component={SinglePortfolioPage}
            /> */}
            <Route  path="/attorneys"   element={<TeamPage/>} />
            <Route  path="/attorneys-single"   element={<SingleTeamPage/>} />
            <Route  path="/contact"   element={<ContactPage/>} />
            <Route  path="/blog-left"   element={<BlogLeftPage/>} />
            <Route  path="/blog-right"   element={<BlogRightPage/>} />
            <Route  path="/blog-fullwidth"   element={<BlogFullWidth/>} />
          </Routes>
        </Router>
      </Fragment>
    );
  }
}

export default App;
