import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logo.png";
import "./style.scss";
import ScrollToTop from "../../ScrollToTop";
import config from "../../config";
const footerLinks = [
  {
    title: "Quick Links",
    menus: [
      { name: "Home", route: "#" },
      { name: "About", route: "AboutUs" },
      { name: "Contact Us", route: "Contact" },
      { name: "Family Law", route: "familyLaw" },
      { name: "Civil Litigation", route: "CivilLitigation" },
      { name: "Privacy Policy", route: "PrivacyPolicy" },
    ],
  },
  {
    title: "_______",
    menus: [
      { name: "Corporate/Business Law", route: "BusinessLaw" },
      { name: "Criminal Law", route: "CriminalLaw" },
      { name: "Immigration Law", route: "ImmigrationLaw" },
      { name: "Real Estate Law", route: "RealEstateLaw" },
      { name: "Wills & Estate", route: "WillsEstate" },
    ],
  },

  {
    title: "Contact Us",
    menus: [
      { name: config.Address.Mississauga.caption },
      { name: config.Address.Mississauga.street },
      { name: config.Address.Mississauga.city },
      { name: config.Address.Mississauga.phone },
      { name: config.Address.Mississauga.email },
    ],
  },
  {
    title: ".",
    menus: [
      { name: config.Address.Cambridge.caption },
      { name: config.Address.Cambridge.street },
      { name: config.Address.Cambridge.city },
      { name: config.Address.Cambridge.phone },
      { name: config.Address.Cambridge.email },
    ],
  },
];

const FooterArea = () => {
  return (
    <footer className="footerArea">
      <div className="footerTopArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footerLogo">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
                <p></p>
              </div>
            </div>
            </div>
            <div className="row"> 
            {footerLinks.map((menu, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                <div className="footerWrap">
                  <h3>{menu.title}</h3>
                  <ScrollToTop />
                  <ul>
                    {menu.menus.map((item, i) => (
                      <li key={i}>
                        {item.route ? (
                          <Link to={`/${item.route}`}>{item.name}</Link>
                        ) : (
                          `${item.name}`
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footerBottomArea">
        <div className="container">
          <div className="footerBottomContent">
            <div className="row">
              <div className="col-md-8 col-sm-10 col-12">
                <span>
                  Privacy Policy | © 2022-2025 Ardent Law Firm. All rights reserved
                </span>
              </div>
              <div className="col-md-4 col-sm-2 col-12">
                <ul className="socialListFooter">
                  <li>
                    <a
                      target={"_blank"}
                      href="https://www.facebook.com/profile.php?id=100076569611115"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target={"_blank"}
                      href="https://www.instagram.com/ardentlawfirm/"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target={"_blank"}
                      href="https://www.linkedin.com/company/ardent-law-professional-corporation/about/"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterArea;
