 
import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker.tsx';
 // Utility to load the Google Maps script asynchronously
 

export default function SimpleMap({Mississauga }){
  const defaultProps = {
    Mississauga: {
      lat: 43.67349,
      lng: -79.67703
    },
    Cambridge: {
      lat: 43.396493,
      lng: -80.326273
    },
    zoom: 16
  };
 // 43.673582591297176, -79.67667448935622
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '300px', width: '450px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDxvf0vP4t61M5aDbA2Q4bzhn19R0J3fyo" }}
        defaultCenter= { Mississauga ? defaultProps.Mississauga : defaultProps.Cambridge }
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
      >
        
   <Marker
            lat= {Mississauga ? defaultProps.Mississauga.lat : defaultProps.Cambridge.lat}
            lng={Mississauga ? defaultProps.Mississauga.lng : defaultProps.Cambridge.lng}
            name="Ardent Law Firm"
            color="blue"
          />
      </GoogleMapReact>
    </div>
  );
}

// import React from "react";
// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

// export default function Map(){
//   const defaultProps = {
//     center: {
//       lat: 10.99835602,
//       lng: 77.01502627
//     },
//     zoom: 11
//   };

//   return (
//     // Important! Always set the container height explicitly
//     <div style={{ height: '100vh', width: '100vw' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: "AIzaSyDxvf0vP4t61M5aDbA2Q4bzhn19R0J3fyo" ,language: 'en'}}
//         defaultCenter={defaultProps.center}
//         defaultZoom={defaultProps.zoom}
//       >
//         <AnyReactComponent
//           lat={59.955413}
//           lng={30.337844}
//           text="My Marker"
//         />
//       </GoogleMapReact>
//     </div>
//   );
// }